//Form
.dynamic-form {

  color: $white;

  input[type="date"]::before {
    color: #ff5722 !important; /* Cambia con il colore desiderato */
  }

  .form-row {
    margin: 0 0 10px;
  }

  textarea.mat-input-element {
    color: $white;
  }

  .errors-box {
    color: rgb(255, 77, 73);
  }

  .min-height-row {
    min-height: 116px;
  }

  .daul-list li:hover, li.selected {
    background-color: $grey !important;
  }

  .record-picker {
    border-color: $grey !important;
  }

  .daul-list li {
    border-color: $grey !important;
  }

  .additional-border-input.invalid-field {
    color: $error;
    border-color: $error;
  }

  .additional-border-input {
    .mat-form-field-flex {
      border: 1px solid $border-color;
      border-radius: 5px;
    }
  }

  .additional-border-input.mat-focused .mat-form-field-flex {
    background: $bg-focus-inputs;
  }

  //Input focus
  .mat-focused {

    .mat-form-field-label {
      color: $primary-active;
    }

    .mat-form-field-outline-start, .mat-form-field-outline-end, .mat-form-field-outline-gap {
      color: $primary-active;
      border-color: $primary-active;
      background: $bg-focus-inputs;
    }
  }

  //Input
  .mat-form-field {
    padding-bottom: 0;

    .mat-form-field-flex {
      border-color: $grey;
      color: $grey;
    }

    .mat-form-field-label {
      color: $grey;
    }

    input {
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.4375em;
      letter-spacing: 0.15px;
      color: $white;
      box-sizing: border-box;
      cursor: text;
      display: inline-flex;
      -webkit-box-align: center;
      align-items: center;
      width: 100%;
      position: relative;
      border-radius: 8px;
      padding: 0;
    }

    ::placeholder {
      color: $grey;
      opacity: 1;
    }

    :-ms-input-placeholder {
      color: $white;
    }

    ::-ms-input-placeholder {
      color: $white;
    }

    .mat-form-field-outline-start, .mat-form-field-outline-end, .mat-form-field-outline-gap {
      color: $border-color;
      border-color: $border-color;
    }
  }

  //Input Disabled
  .mat-form-field-disabled {
    input {
      cursor: not-allowed;
    }

    .mat-form-field-outline-start, .mat-form-field-outline-end, .mat-form-field-outline-gap {
      background: $disabled-bg;
    }

    .mat-form-field-wrapper {
      background: $disabled-bg;
    }

    .mat-datepicker-toggle-default-icon {
      color: $disabled-input-bg;
    }
  }

  .date-input.mat-form-field-disabled .mat-form-field-flex {
    background: $disabled-input-bg;
  }

  //Input invalid
  .invalid-field {
    .mat-form-field-outline-start, .mat-form-field-outline-end, .mat-form-field-outline-gap {
      color: $error;
      border-color: $error;
    }

    .mat-form-field-flex {
      border-color: $error !important;
      color: $error;
    }
  }

  .label-form {
    line-height: 1.6;
    font-weight: 500;
    font-size: 1.1rem;
  }

  .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 0.7em 0 0.7em 0;
  }

  .mat-form-field-appearance-fill {

    .mat-form-field-flex {
      padding: 0.3em 0.75em 0 0.75em;
    }

    .mat-form-field-underline::before {
      display: none !important;
    }

    .mat-form-field-ripple {
      display: none;
    }
  }

  .mat-form-field-appearance-outline, .mat-form-field-wrapper {
    margin: 0;
    padding: 0;
  }

  .disabled-dropdown mat-form-field {
    background: rgba(191, 191, 191, 0.2);
    cursor: not-allowed;
  }

  app-dropdown-form {
    .disabled-dropdown .mat-form-field-flex {
      background: $disabled-bg;
      cursor: not-allowed;

      .mat-select {
        cursor: not-allowed;
      }
    }
  }

  .date-input.invalid-field {
    border-color: $error !important;
    color: $error;
  }

  .date-input.mat-focused {
    background: $bg-focus-inputs;
  }

  .date-time-picker {
    border: 1px solid rgba(191, 191, 191, 0.4509803922);
    border-radius: 5px;
    margin-top: 4px;

    .mat-form-field-flex {
      padding-top: 0;
    }

    .mat-form-field-label {
      margin-top: .25em;
    }

    .mat-form-field-infix {
      padding: 0 0 0.35em;
      margin-top: -.25em;
    }

    input {
      width: 88% !important;
    }

    .mat-datepicker-toggle {
      width: 5% !important;
    }

    .mat-focus-indicator {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-size: inherit;
      width: 2.5em;
      height: 2.5em;
    }
  }

  .date-input {
    border: 1px solid rgba(191, 191, 191, 0.4509803922);
    border-radius: 5px;
    margin-top: 4px;

    .mat-form-field-flex {
      padding-top: 0;
    }

    .mat-form-field-label {
      margin-top: -1px;
    }

    mat-hint {
      color: $white;
      margin-top: 1px;
      font-size: 9px;
    }

    .mat-form-field-appearance-fill .mat-form-field-subscript-wrapper {
      margin: 0;
    }
  }

  app-date-input {
    mat-hint {
      margin-top: 5px !important;
      font-size: 8px !important;
    }

    .mat-form-field-appearance-fill .mat-form-field-subscript-wrapper {
      margin: 0;
    }
  }

  mat-month-view {
    tr {
      background-color: white !important;
    }
  }

  mat-datepicker-toggle {
    color: $white;
  }

  .mat-select-value {
    color: $white !important;
  }

  .input-repeater {
    padding-right: 20px;
    margin-bottom: 4px;

    .mat-form-field-flex {
      padding-top: 0;
    }

    .mat-form-field-label {
      margin-top: -1px;
    }

  }

  app-control {
    padding-right: 20px;
    margin-bottom: 4px;
  }

  .mat-select-arrow {
    color: $white;
  }

  mat-select {
    font-size: 1rem;
  }

  .mat-ripple-element {
    display: none;
  }

  .mat-tab-list {
    min-height: 48px;
    display: flex;
    border-bottom: 1px solid rgba(234, 234, 255, 0.12);
  }

  .title-repeater {
    background-color: rgba(102, 108, 255, 0.12);
  }

  .div-sections {
    border-color: rgba(102, 108, 255, 0.12);
  }
}
