.mat-table {

  .filter-rows th {
    top: 44px !important;
    background: #444865;
  }

  .inline-insert-data-header th {
    top: 95px !important;
    background: #444865;
  }

  .header-rows th {
    top: -2px !important;
  }

  .mat-header-row {
    height: 52px;
    background-color: #3a3e5b;

    th {
      color: white;
    }
  }

  .mat-sort-header.mat-header-cell, mat-sort-header-container {
    cursor: pointer;
  }

  .mat-checkbox-background {
    background-color: #3a3e5b;
  }

  .mat-sort-header-indicator {
    color: white;
  }

  .mat-sort-header-stem {
    background: white;
  }

  mat-icon {
    position: relative !important;
    float: right !important;
    top: -3px !important;
    cursor: pointer !important;
    color: rgba(255, 255, 255, 0.54) !important;
  }

  .mat-paginator-page-size-label {
    @apply text-base
  }

  .mat-form-field-appearance-fill .mat-form-field-underline::before {
    background-color: ghostwhite !important;
  }

  .mat-select-min-line,
  .mat-select-value-text {
    color: ghostwhite !important;
  }

  .mat-header-row[_ngcontent-dln-c321] .mat-header-cell[_ngcontent-dln-c321] {
    border-top: 1px solid #6c757d !important;
    border-bottom: 1px solid #6c757d !important;
  }

  .mat-row .mat-cell {
    border-color: #6c757d !important;
    background: var(--dark, #282A42) !important;
    color: $white;
  }

  #paginator-table {
    background: #3a3e5b;
    color: rgb(226 232 240 / var(--tw-text-opacity));
  }

  ::ng-deep .mat-form-field-underline {
    background-color: darkgray !important;
  }

  ::ng-deep .mat-form-field-label, .mat-form-field-autofill-control {
    color: lightgray !important;
  }

  .date-input {

    svg.mat-datepicker-toggle-default-icon {
      @apply h-6 w-6 mr-4;
      fill: $white;
    }

    .mat-icon-button {
      @apply h-6 w-6;
    }

    .mat-form-field-infix {
      padding: 0 0 5px;
    }

    .mat-date-range-input-end-wrapper {
      width: calc(50% - 4px);
      text-align: center;
    }

    .mat-date-range-input-container {
      align-items: unset;
    }

    .mat-date-range-input-separator {
      color: $white;
    }

    .mat-date-range-input {
      margin-right: 0.1rem;
    }

    .mat-datepicker-toggle {
      margin-left: 0.9rem;
    }

    .mat-date-range-input-start-wrapper {
      width: calc(50% - 4px);
      text-align: center;
    }

    .mat-date-range-input {
      width: 78%;
      position: relative;
      display: inline-flex;
    }

    .mat-form-field-flex {
      border: 1px solid rgba(191, 191, 191, 0.4509803922);
      border-radius: 5px !important;
      padding-bottom: 0 !important;
      padding-top: 0 !important;
      background-color: var(--dark, #282A42) !important;
    }

    .mat-form-field-wrapper {
      padding: 0;
    }

    .mat-form-field-label {
      margin-top: -1px;
    }

    mat-hint {
      color: $white;
      margin-top: 1px;
      font-size: 9px;
    }

    .mat-form-field-appearance-fill .mat-form-field-subscript-wrapper {
      margin: 0;
    }

    .mat-form-field-underline {
      display: none;
    }
  }

  mat-datepicker-toggle {
    color: $white;
  }

  mat-date-range-input {
    height: 19px;
  }

  .mat-form-field {
    padding-bottom: 0 !important;

    .mat-form-field-flex {
      border-color: $grey;
      color: $grey;
    }

    .mat-form-field-label {
      color: $grey;
    }

    input {
      font-weight: 400;
      font-size: 0.9rem;
      line-height: 1.4375em;
      letter-spacing: 0.15px;
      color: $white;
      box-sizing: border-box;
      cursor: text;
      display: inline-flex;
      -webkit-box-align: center;
      align-items: center;
      width: 100%;
      position: relative;
      border-radius: 8px;
      padding: 0;
    }

    ::placeholder {
      color: $grey;
      opacity: 1;
    }

    :-ms-input-placeholder {
      color: $white;
    }

    ::-ms-input-placeholder {
      color: $white;
    }

    .mat-form-field-outline-start, .mat-form-field-outline-end, .mat-form-field-outline-gap {
      color: $border-color;
      border-color: $border-color;
    }
  }

}

.table {

  thead {
    height: 52px;
    background-color: #3a3e5b;

    th {
      color: white;
    }
  }

  td {
    border-color: #6c757d !important;
    background: var(--dark, #282A42) !important;
    color: $white;
  }

  .row-selected {
    background: $primary-hover !important;
  }

  .icon-cell {
    width: 30px;
    text-align: center;
  }

  .icon-plus {
    color: rgb(223, 159, 35);
    font-weight: bold;
  }
}
